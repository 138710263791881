import {useMutation} from "react-query"
import axios from "axios"

export function PostToCupload() {
    return useMutation('cuploadPost', body => {
        const data = axios.post(
            "https://kokeile.annieadvisor.com/api/cupload.php/",
            body
        )
        return data
    })
}

export function PostToResend() {
    return useMutation('resendPost', body => {
        const data = axios.post(
            "https://kokeile.annieadvisor.com/api/resend.php/tryme",
            body
        )
        return data
    })
}

/*
https://kokeile.annieadvisor.com/api/cupload.php/
JSON BODY

{
    "nickname":"JoonasTestaaTaasTaas",
    "phonenumber":"+358503575130"
}
POST https://kokeile.annieadvisor.com/api/resend.php/1638344503252
JSON BODY

["COG8HNNHIKzP"]
 */